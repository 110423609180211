@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@100;200;300;400;500;600;700;800;900&display=swap');

html{
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: 'League Spartan', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container{
  max-width: 1260px;
}

img{
  @apply max-w-full h-auto;
}

.btn{
  @apply py-3 px-6 inline-block bg-neutral-950 text-base font-normal text-white shadow outline-none rounded focus:outline-none hover:bg-primary hover:text-white transition-all duration-500;
}

.btn-primary{
  @apply bg-primary hover:bg-neutral-900 hover:text-white;
}
.btn-secondary{
  @apply bg-secondary hover:bg-neutral-900 hover:text-white;
}
.btn-icon{
  @apply w-12 h-12 bg-neutral-950 text-white rounded-full shadow-inner flex items-center justify-center hover:bg-primary hover:text-white transition-all duration-500;
}

.section-gap{
  @apply py-10 md:py-16;
}

.check-list li{
  @apply relative pl-8;
}
.check-list li:before{
  content: "";
  @apply block w-5 h-5 absolute top-1 left-0 bg-cover bg-center;
  background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>');
}
.slick-dots{
  @apply !-bottom-14;
}
.slick-dots li button{
  @apply !w-5 !h-5 !border !border-solid !border-white rounded-full !bg-transparent !flex items-center justify-center;
}
.slick-dots li button:before{
  @apply !w-2 !h-2 bg-white rounded-full !relative !text-transparent;
}

.menu-wrapper.active{
  @apply top-24 opacity-100;
}